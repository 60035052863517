<template>
   <el-dialog
        title="修改密码"
        :visible.sync="modal1"
        :modal-append-to-body="false"
        :close-on-click-modal="false"
        @close="handleCancel"
        width="400px"
        center>
        <div>
            <el-form ref="form" :rules="rules" :model="password" label-width="80px">
                <el-form-item label="旧密码" prop="oldPwd">
                    <el-input v-model="password.oldPwd" show-password></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="newPwd">
                    <el-input v-model="password.newPwd" show-password></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="d-flex">
                        <el-button class="flex-1" type="primary" @click="submitForm('form')">确定</el-button>
                        <el-button @click="handleCancel">取消</el-button>
                    </div>
                </el-form-item>
            </el-form>
        </div>
    </el-dialog>
</template>
<script>
import {mapMutations} from 'vuex'
import {resetPassword} from '@/request/api/user'
export default {
    props:{
        show:{
            type:Boolean
        }
    },
    data(){
        return {
            modal1:this.$props.show,
            password:{
                newPwd:'',
                oldPwd:''
            },
            rules: {
                oldPwd: [
                    { required: true, message: '旧密码必填', trigger: 'blur' }
                ],
                newPwd: [
                    { required: true, message: '新密码必填', trigger: 'blur' }
                ],
            }
        }
    },
    watch:{
        show:function(val){
            this.modal1 = val
        }
    },
    methods:{
        ...mapMutations(['logout']),
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
            if (valid) {
                //修改密码
                this.req()
            } else {
                return false;
            }
            });
        },
        req(){
            resetPassword(this.password).then(res=>{
                if(res.code!=0) return this.$notify({title: '失败',message: res.msg,type: 'error'});
                this.$notify({title: '成功',message: res.msg,type: 'success'});
                this.logout()
                this.$router.push('/login')
            })
        },
        handleCancel(){
            this.modal1 = false
            this.$emit('on-close',false)
        }
    }
}
</script>
<style scoped>

</style>